.subtitle-list {
    margin-top: 20px;
    overflow-y: auto;
    height: 100%;
    padding-right: 4px;
}

.subtitle-item {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
}

.subtitle-item .avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.subtitle-item .subtitle-name-content {
    flex-grow: 1;
}

.subtitle-item .subtitle-name-content .subtitle-name {
    display: block;
    font-size: 12px;
    color: var(--mui-text-secondary);
    margin-bottom: 8px;
}

.subtitle-item .subtitle-name-content .subtitle-content {
    display: block;
    font-size: 16px;
    color: #000000;
    background-color: #E8F5FF;
    padding: 16px;
    border-radius: 8px;
    line-height: 28px;
    position: relative;
    opacity: 0.6;
}

.subtitle-item .subtitle-name-content .subtitle-content.font-size-12 {
    font-size: 12px;
    padding: 8px;
    line-height: 18px;
}

.subtitle-item .subtitle-name-content .subtitle-content.font-size-20 {
    font-size: 20px;
}

.subtitle-item .subtitle-name-content .subtitle-content.is-final {
    color: #232323;
    opacity: 1;
}

.subtitle-item .subtitle-name-content .subtitle-content::before {
    content: "";
    position: absolute;
    top: 6px;
    left: -14px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 30px solid #E8F5FF;
    transform: rotate(45deg);
    z-index: -1;
}

.subtitle-item.is-mine .avatar {
    order: 2;
}

.subtitle-item.is-mine .subtitle-name-content {
    order: 1;
}

.subtitle-item.is-mine .subtitle-name-content .subtitle-name {
    text-align: right;
}

.subtitle-item.is-mine .subtitle-name-content .subtitle-content {
    background-color: #F1F1F1;
}

.subtitle-item.is-mine .subtitle-name-content .subtitle-content::before {
    border-right: 30px solid #F1F1F1;
    right: -14px;
    left: unset;
    transform: rotate(135deg);
}

.subtitle-item .subtitle-name-content span {
    display: block;
}

.subtitle-item .subtitle-name-content .subtitle-translate {
    margin-top: 14px;
}

.subtitle-item .subtitle-name-content .subtitle-translate::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #CCCCCC;
    margin-bottom: 14px;
}

.subtitle-item .subtitle-name-content .subtitle-translate.hide-original-text {
    margin-top: 0;
}

.subtitle-item .subtitle-name-content .subtitle-translate.hide-original-text::before {
    display: none;
}

.translate-share-screen {
    width: 100%;
    margin-top: 10px;
    cursor: pointer;
}
