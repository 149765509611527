.effect-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.effect-name {
    font-size: 16px;
    color: #ffffff;
}

.effect-list .effect-item {
    margin: 0;
    background-color: #E9EEF3;
    border-radius: 10px;
    border: 2px transparent;
    height: 70px;
    width: 70px;
    cursor: pointer;
}

.effect-list .effect-item .material-icons {
    padding: 0;
    font-size: 26px;
    color: rgb(95,99,104);
    margin: auto;
}

.effect-item svg {
    fill: currentcolor;
    stroke: currentcolor;
}

.effect-item.active {
    color: #1A73E8;
    border: 2px solid #1A73E8;
}

.effect-item.active .material-icons {
    color: #1A73E8;
}

.effect-item.active svg {
    color: #1A73E8;
}

.effect-background {
    background-position: center center;
    background-size: cover;
}

.btn-background-upload {
    display: flex;
}

.effect-list .background-upload {
    position: relative;
}

.effect-list .background-upload .material-icons {
    position: absolute;
    right: -15px;
    top: -15px;
}

.effect-list .background-upload .material-icons {
    cursor: pointer;
    display: none;
    color: #E0E0E0;
}

.effect-list .background-upload:not(.active):hover .material-icons {
    display: block;
}