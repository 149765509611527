.body-content {
    display: flex;
    padding-top: 54px;
    min-height: 100vh;
}

.body-content .content {
    flex-grow: 1;
    padding: 48px 48px 48px 309px;
    background-color: #ffffff;
}

.btn-create-subtitles[disabled] {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
}

@media only screen and (max-width: 768px) {
    .body-content .content {
        padding: 48px;
    }
}
