.other-users {
    background-color: #333333;
    width: calc(100% / 6);
    height: 20%;
}

.other-users .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.other-users .other-users-avatar {
    display: flex;
}

.other-users .other-users-avatar img {
    width: 50px;
    border-radius: 50%;
}

.other-users .other-users-avatar img:nth-child(n+2) {
    margin-left: -30px;
}

.other-users .other-users-span {
    font-size: 14px;
    color: #ffffff;
    margin-top: 16px;
    display: block;
}