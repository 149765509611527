.cushion-page {
    width: 100%;
    min-height: 100vh;
    background-color: #232323;
    padding-bottom: 20px;
}

.cushion {
    width: calc((100vh - 240px) * 1.8);
    margin-left: auto;
    margin-right: auto;
    max-width: 900px;
}

.student-hangout-text {
    display: block;
    font-size: 26px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 8px;
    word-break: break-word;
}

.student-hangout {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}

.student-hangout .room-name {
    flex: 1;
}

.student-hangout .user-in-call {
    display: flex;
}

.student-hangout .user-in-call > .list-connections-length {
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 4px;
    margin-left: 3px
}

.join-meeting-btn {
    position: relative;
    width: 200px;
}

.media-stream {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 8px;
    max-height: calc(100vh - 240px);
    min-height: 200px
}

.media-stream video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.media-stream .img-user-default {
    width: 100%;
    height: 100%;
    object-fit: none;
    background-color: #E0E0E0;
}

.media-stream .setting-media-stream {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    gap: 10px;
    margin-bottom: 10px;
    z-index: 2;
}

.media-stream .setting-media-stream .noise-suppression-icon img {
    width: 28px;
}

.setting-devices {
    display: flex;
    justify-content: space-between;
}

.setting-devices .select-devices {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
}

.setting-devices .select-devices .devices {
    margin-right: 10px;
}

.setting-devices .select-devices select {
    width: 170px;
    font-size: 14px;
    color: #CCCCCC;
    border: none;
    padding-bottom: 8px;
    border-bottom: 1px solid #666666;
    background-color: #232323;
    outline: none;
    margin-bottom: 8px;
    text-indent: 20px;
}

.setting-devices .select-devices select:hover {
    color: #3386CC;
    border-bottom: 1px solid #3386CC;
}

.setting-devices .select-devices select option {
    background-color: #FFFFFF;
    color: #232323;
}

.setting-devices > span {
    font-size: 14px;
    color: #CCCCCC;
}

.setting-devices > span > a {
    text-decoration: none;
}

.setting-devices .devices {
    position: relative;
}

.setting-devices .devices img {
    width: 14px;
    position: absolute;
    top: 6px;
    left: 4px;
}

.setting-devices .devices img.active {
    display: none;
}

.setting-devices .devices:hover img {
    display: none;
}

.setting-devices .devices:hover img.active {
    display: block;
}

.join-meeting-btn .join-meeting-button-mobile {
    display: none;
}

.tooltip-join-meeting-button-mobile {
    display: none;
}

.tooltip-join-meeting-button-desktop {
    display: block;
}

.cushion-page video.mirroring-webcam {
    transform: rotateY(180deg);
}

.media-stream .loading-media {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.65);
    font-size: 16px;
    color: #FFFFFF;
    z-index: 1;
}

.media-stream .loading-media .loading-media-text {
    transform: translateY(16px);
}

.media-stream .loading-media .loading-media-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 100%;
    justify-content: center;
}

.start-call-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    max-width: 95%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 10px 36px;
}

.start-call-message p:first-child {
    margin-bottom: 5px;
}

.start-call-message p {
    color: #FFFFFF;
    font-size: 16px;
}

.start-call-message .start-call-message-button {
    color: #FFFFFF;
    background-color: #3386CC;
    border-radius: 30px;
    font-size: 13px;
    padding: 4px 10px 5px;
    text-align: center;
    box-shadow: none;
    display: inline-block;
}

.start-call-message .start-call-message-button img {
    height: 13px;
    margin-right: 3px;
    transform: translateY(2px);
}

@media only screen and (max-width: 1280px) {

    .setting-devices .select-devices .devices {
        width: calc((100% / 3) - 15px);
    }

    .setting-devices .select-devices select {
        width: 100%;
    }

    .setting-devices > span {
        max-width: 125px;
    }
}

@media only screen and (max-width: 900px) {
    .cushion {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .student-hangout {
        padding-left: 15px;
        padding-right: 15px;
    }

    .media-stream {
        width: 100%;
        height: calc(100vw / 1.8);
        margin-bottom: 10px;
    }

    .setting-devices {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
    }

    .setting-devices .select-devices .devices {
        width: calc(50% - 15px);
    }

    .setting-devices .select-devices select {
        margin-bottom: 10px;
    }

    .setting-devices > span {
        max-width: 100%;
        float: right;
        margin-top: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .join-meeting-btn {
        width: 50px;
    }

    .join-meeting-btn .join-meeting-button-desktop {
        display: none;
    }

    .join-meeting-btn .join-meeting-button-mobile {
        display: block;
    }

    .tooltip-join-meeting-button-mobile {
        display: block;
    }

    .tooltip-join-meeting-button-desktop {
        display: none;
    }

    .media-stream .loading-media .loading-media-text {
        transform: translateY(4px);
    }

    .start-call-message {
        width: fit-content;
        min-width: 95%;
        padding: 5px 5px 10px;
    }

    .start-call-message p {
        font-size: 14px;
    }

    .start-call-message .start-call-message-button {
        font-size: 12px;
        padding: 2px 10px 4px;
    }

    .start-call-message .start-call-message-button img {
        height: 12px;
    }
}
