.sidebar-right {
    height: 100%;
    position: fixed;
    top: 0;
    background-color: var(--mui-background-default);
    padding: 16px;
    overflow: hidden;
    z-index: 30;
    transition: all 0.5s;
    visibility: hidden;
    border-left: 1px solid var(--mui-icon-border-default);
}

.sidebar-right.is-open {
    right: 0 !important;
    z-index: 31;
    visibility: visible;
}

.sidebar-right .sidebar-right-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.sidebar-right .sidebar-right-head .sidebar-right-container-title {
    display: flex;
}

.sidebar-right .sidebar-right-head .sidebar-right-container-title svg{
    margin-top: 3px;
    margin-right: 2px;
}

.sidebar-right .sidebar-right-head .close-icon {
    transform: translateY(-5px);
}

.sidebar-right .sidebar-right-body {
    height: calc(100% - 50px);
}

.sidebar-right .sidebar-right-container-title .sidebar-right-title {
    font-size: 16px;
    color: var(--mui-text-primary);
    margin-right: 10px;
    font-weight: bold;
}

.sidebar-right .sidebar-right-container-title .button-icon {
    background-color: unset;
    width: 20px;
    height: 20px;
}

.sidebar-right .sidebar-right-container-title .button-icon svg{
    margin-top: 2px;
    width: 20px;
}

.sidebar-right .show-subtitle-translation {
    font-size: 14px;
    transform: translateY(-3px);
    display: inline-block;
    cursor: pointer;
}

.sidebar-right .show-subtitle-translation > svg {
    transform: translate(0px, 6px);
}

.translation-language {
    padding: 16px;
    min-width: 323px;
}

.translation-language label {
    display: block;
    color: var(--mui-text-secondary);
}

.sidebar-handle-width {
    cursor: col-resize;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    background-color: transparent;
}

@media only screen and (max-width: 600px) {
    .sidebar-right {
        width: 100% !important;
        right: -100vw !important;
        transition: none !important;
    }

    .container-display.narrow .list-btn {
        right: 100vw !important;
    }

    .list-btn {
        transition: none !important;
    }

    .container-display {
        transition: none !important;
    }

    .container-features {
        transition: none !important;
    }
}