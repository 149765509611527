header.header-element {
    width: 100%;
    height: 54px;
    background: #3945BC;
    box-sizing: border-box;
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

header.header-element .logo {
    min-width: 300px;
}

header.header-element .logo img {
    height: 34px;
    margin-top: 10px;
    margin-left: 15px;
}

header.header-element .toggled-sidebar-left-button {
    margin-top: 10px;
    margin-left: 15px;
    background: none;
    border: none;
    cursor: pointer;
    display: none;
}

.user-profile {
    margin-top: 9px;
    margin-right: 15px;
}

.user-profile .user-name {
    font-size: 14px;
    color: #ffffff;
    margin-left: 5px;
}

.user-profile .user-profile-avatar {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
}

.header-element .login {
    margin-top: 5px;
    display: block;
}

@media only screen and (max-width: 768px) {
    header.header-element .toggled-sidebar-left-button {
        display: inline-block;
    }
}