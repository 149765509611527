.breakout-room {
    text-align: left;
    color: #232323;
}

.breakout-room .sidebar-right .sidebar-right-container-title .sidebar-right-title {
    font-weight: bold;
    font-size: 16px;
}

.breakout-room .sidebar-right {
    overflow-y: auto;
}

.breakout-room .sidebar-right .sidebar-right-body {
    height: calc(100% - 60px);
}

.title-main-room, .title-sub-room {
    font-weight: bold;
    font-size: 14px;
}

.title-main-room {
    margin-top: 17px;
}

.title-main-room svg, .title-sub-room svg  {
    /*margin-top: 5px;*/
    vertical-align: middle;
}

.title-main-room span,
.title-sub-room span {
    margin-left: 4px;
    vertical-align: middle;
    color: var(--mui-text-primary);
}

.main-room-info, .sub-room-info {
    margin-left: 13px;
    margin-top: 22px;
}

.main-room-info li,.sub-room-info li {
    padding: 0;
}

.main-room-info svg, .sub-room-info svg {
    margin-top: -6px;
    margin-left: 2px;
}

.sub-room {
    margin-top: 10px;
}

.main-room-info .room-name,
.sub-room-info .room-name {
    color: #3386CC;
    text-align: left;
    font-size: 14px;
    margin-left: 4px;
    margin-top: -4px;
}

.container-name-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-name-icon div {
    cursor: pointer;
}

.container-sub-participant {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: -9px;
}

.container-delete-participant {
    display: none;
    background-color: var(--mui-icon-background-color-custom);
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.container-sub-participant:hover .container-delete-participant {
    display: block;
}

.container-delete-participant svg{
    transform: translate(3px, -8px);
}

.main-room-participant .rooms-user,
.container-sub-participant .rooms-user {
    margin: 8px 0 0 2px;
    height: 35px;
    position: relative;
}

.main-room-participant .avatar,
.sub-room-participant .avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 8px;
}

.main-room-participant .rooms-user-name,
.sub-room-participant .rooms-user-name {
    font-size: 14px;
    transform: translateY(0);
    cursor: default;
    color: var(--mui-text-primary);
    display: inline-block;
    height: 20px;
    line-height: 14px;
    max-width: 167px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
}

.container-sub-participant svg {
    cursor: pointer;
}

.sub-room-participant .not-participant {
    font-size: 14px;
    color: var(--mui-text-secondary);
    margin-bottom: 4px;
    margin-top: 7px;
    margin-left: 1px;
}

.assign-members {
    color: #3386CC;
}

.assign-members .assign-members-text {
    font-size: 12px;
    cursor: pointer;
    margin-left: 3px;
}

.sub-room-index {
    margin-bottom: 14px;
}

.sub-room-index::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #E3E9EF 0 0 no-repeat padding-box;
    margin: 8px 0;
}

.button-add-breakout-room {
    height: 28px;
    width: 298px;
    border: 1px solid #3386CC !important;
    border-radius: 20px !important;
    text-align: left;
    font-size: 14px;
    margin-left: 12px !important;
    background: #FFFFFF !important;
    color: #3386CC !important;
}

.button-add-breakout-room:hover {
    background: #3386CC !important;
    color: #FFFFFF !important;
}

.button-add-breakout-room:hover svg {
    fill: #FFFFFF;
}

.add-breakout-room .span-add-breakout-room {
    margin-left: 4px;
}

.setup-breakout-room {
    margin-top: 18px;
}

.setup-breakout-room .time-text,
.setup-breakout-room .time {
    margin-left: 16px;
}

.setup-breakout-room .time-text svg {
    vertical-align: middle;
}

.setup-breakout-room .time-text span {
    font-size: 14px;
    font-weight: bold;
    margin-left: 4px;
    color: var(--mui-text-primary);
}

.setup-breakout-room .time span {
    font-size: 14px;
    color: var(--mui-text-secondary);
}

.input-adornment-end {
    display: inline-block;
    margin-top: 8px;
    margin-left: 3px;
}

.start-join-breakout-room {
    width: 318px;
    height: 40px;
    background: #3386CC 0 0 no-repeat padding-box !important;
    border: 1px solid #3386CC !important;
    border-radius: 20px !important;
    text-align: left;
    font-size: 14px;
    color: #FFFFFF !important;
    margin-top: 24px !important;
    margin-bottom: 16px !important;
}

.start-join-breakout-room:hover {
    background: #FFFFFF !important;
    color: #3386CC !important;
}

.note-breakout-room {
    font-size: 14px;
    color: var(--mui-text-secondary);
    padding-bottom: 10px;
}

.start-join-breakout-room:disabled, .discontinue-breakout-room:disabled {
    opacity: 0.5;
}

.discontinue-breakout-room {
    width: 318px;
    height: 40px;
    background-color: var(--mui-error) !important;
    border: 1px solid var(--mui-error) !important;
    border-radius: 20px !important;
    text-align: left !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    margin-top: 24px !important;
    margin-bottom: 16px !important;
}

.discontinue-breakout-room:hover {
    background: #FFFFFF !important;
    color: var(--mui-error) !important;
}

.breakout-room-started, .disable-click {
    pointer-events: none;
    opacity: .5;
}
