.container-features {
    width: 100%;
    background-color: var(--mui-color-mode-one);
    padding: 10px 16px;
    margin: 0;
    position: fixed;
    z-index: 3;
    bottom: 0;
    transition: width 0.5s;
}

.container-features.is-expand-close {
    bottom: -60px;
    animation-duration: 0.5s;
    animation-name: toUp;
}

.container-features.is-expand-more {
    bottom: 0;
    animation-duration: 0.5s;
    animation-name: move;
}

@keyframes move {
    from {
        bottom: -60px;
    }

    to {
        top: unset;
        bottom: 0;
    }
}

@keyframes toUp {
    from {
        top: unset;
        bottom: 0;
    }

    to {
        bottom: -60px;
    }
}

.is-first-login{
    top: unset;
    bottom: 0;
}

.container-display.narrow .container-features {
    width: calc(100% - 350px);
}

.basic-features {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    flex-basis: 396px;
    flex-grow: 0;
    flex-shrink: 0;
}

.container-setting-feature {
    display: flex;
    align-items: center;
    background-color: #2B323A;
    border-radius: 20px;
    height: 40px;
    flex-basis: 65px;
    flex-grow: 0;
    flex-shrink: 0;
}

.setting-feature {
    position: relative;
}

.setting-feature .button-icon.bg-red {
    background-color: #2B323A;
}

.setting-feature .button-icon {
    height: 28px !important;
    width: 28px !important;
}

.setting-feature .button-icon img {
    width: 9px !important;
    margin-left: 4px;
    margin-top: 0 !important;
}

.container-box {
    display: flex;
    flex-direction: column;
}

.container-box .header-container {
    color: #ffffff;
    font-size: 62px;
    display: flex;
}

.container-box .header-container span {
    flex: 1;
    padding-left: 42px;
}

.customized-dialog .button-icon {
    background: none;
    width: 30px;
    height: 30px;
    float: right;
}

.container-box .content-container .content-dialog > span {
    color: var(--mui-text-primary);
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 10px;
    display: block;
}

.container-box .content-container .content-first span {
    padding-top: 0px;
}

.container-box .content-container .content-dialog select {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    font-size: 16px;
    padding: 0 10px;
    border: 1px solid #0B0C0E;
}

.container-features .button-icon {
    width: 40px;
    height: 40px;
}

.container-features .button-icon img {
    width: 20px;
    margin-top: 4px
}

.container-features .btn-expand {
    width: 60px;
    height: 20px;
    display: block;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    border-radius: 4px 4px 0 0;
    background-color: var(--mui-color-mode-one);
    cursor: pointer;
}

.container-features .btn-expand .btn-expand-less {
    width: 21px;
}

.share-screen {
    position: relative;
}

.features-title {
    position: absolute;
    font-size: 16px;
    color: #ffffff;
    font-weight: bold;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    gap: 4px;
}

.features-title .span-icon-record {
    transform: translate(0px,1px);
}

.features-title .icon-not-recording {
    color: #FFFFFF;
}

.error-device {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FA7B17;
    left: 62%;
    top: -5%;
}

.is-seen-message {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--mui-error);
    left: 26px;
    top: 4px;
}

.is-seen-message-mobile {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: var(--mui-error);
    left: 19px;
    top: 0;
}

.error-device-icon {
    position: absolute;
    color: #ffffff;
    font-size: 12px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

#basic-menu .show-mobile {
    display: none !important;
}

@media only screen and (max-width: 600px)  {
    .features-title {
        display: none;
    }

    .basic-features .hidden-mobile {
        display: none;
    }

    #basic-menu .show-mobile {
        display: block !important;;
    }
}

@media only screen and (min-width: 601px) {
    .container-features {
        display: flex;
        justify-content: space-between;
    }

    .features-title {
        position: unset;
        font-size: 16px;
        color: #ffffff;
        font-weight: bold;
        top: 50%;
        transform: unset;
        width: calc((100% - 396px) / 2);
        padding-right: 20px;
    }

    .features-title .room-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .extra {
        width: calc((100% - 396px) / 2);
    }
}