.participant-list {
    margin-top: 16px;
    overflow-y: auto;
    max-height: calc(100% - 50px);
}

.participant {
    position: relative;
    margin-bottom: 8px;
    height: 35px;
    display: flex;
}

.participant .avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 8px;
}

.participant .participant-name {
    font-size: 14px;
    color: var(--mui-text-primary);
    display: inline-block;
    width: auto;
    max-width: 140px;
    height: 20px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: default;
    transform: translateY(10px);
    margin-left: 6px;
}

.participant .info-network img {
    height: 14px;
    transform: translateY(6px);
}

.participant .speaking-time {
    width: 50px;
    margin-right: 8px;
}

.participant .speaking-time > div {
    transform: translateY(25px);
}

.sort-item {
    text-align: right;
}

.sort-item span {
    font-size: 14px;
    color: var(--mui-text-secondary);
}

.sort-item input {
    margin-left: 5px;
}

.speaking-list-change-sort .participant-type {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.participant-type .participant-title {
    margin-bottom: 14px;
}

.participant-type .participant-title > svg {
    transform: translateY(2px);
}

.participant-type .participant-title > span {
    font-size: 14px;
    font-weight: bold;
    margin-left: 4px;
    color: var(--mui-text-primary);
}

.participant .btn-edit-name > svg {
    transform: translate(4px, 4px);
}

.participant .participant-mic .button-icon {
    width: 24px;
    height: 24px;
}

.participant .participant-mic .participant-mic-on {
    background-color: #3386CC;
}

.participant .participant-mic .participant-mic-off {
    background-color: #FFFFFF;
}

.participant .participant-mic .button-icon > img {
    width: 13px;
}

.participant .participant-mic .participant-mic-on:hover:not(.disable-hover) {
    background-color: #E3E9EF;
}

.participant .participant-mic .participant-mic-off:hover {
    cursor: unset;
}

.participant .participant-mic .participant-mic-on-hover {
    display: none;
}

.participant .participant-mic .participant-mic-on:hover:not(.disable-hover) .participant-mic-on-hover {
    display: inline-block;
}

.participant .participant-mic .participant-mic-on:hover:not(.disable-hover) .participant-mic-on-normal {
    display: none;
}

.participant .participant-mic .participant-mic-on.disable-hover {
    cursor: unset;
}

.participant .participant-edit-name {
    flex-grow: 1;
}

.participant .participant-edit-name .btn-edit-name {
    cursor: pointer;
}

.participant .participant-mic {
    transform: translateY(6px);
}

.is-participant-owner .participant-name {
    cursor: pointer;
}

