.sidebar-left .menu-name {
    font-size: 16px;
    color: #232323;
    font-weight: bold;
    margin-bottom: 24px;
    padding-left: 20px;
}

.sidebar-footer {
    padding-top: 16px;
    border-top: 1px solid #e3e9ef;
    width: 100%;
    padding-bottom: 50px;
    margin-top: 8px;
}

.sidebar-footer a {
    padding-left: 20px;
    padding-bottom: 16px;
    display: block;
    font-size: 12px;
    color: #666666;
    text-decoration: none;
}

.sidebar-footer a:hover {
    color: #3386CC;
}

.sidebar-footer .copyright {
    display: block;
    font-size: 12px;
    color: #666666;
    text-align: center;
    padding-top: 16px;
    border-top: 1px solid #e3e9ef;
}

.select-group-project {
    padding: 10px;
}
