.appsync-sub-title {
    padding: 20px;
    width: 100%;
}

.appsync-subtitle-settings .btn-subtitle-settings {
    margin: 6px 10px;
}

.appsync-subtitle-settings a > svg {
    transform: translateY(7px);
}
