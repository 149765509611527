.breakout-room-dialog .rooms-user {
    margin-bottom: 0;
    margin-top: 8px;
}

.breakout-room-dialog .rooms-user div {
    margin-bottom: 0;
}

.breakout-room-dialog .rooms-user img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 8px;
}

.breakout-room-dialog .rooms-user .rooms-user-name {
    transform: translateY(0);
    color: var(--mui-text-primary);
    display: inline-block;
    font-size: 14px;
    height: 20px;
    line-height: 14px;
    max-width: 167px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
}

.breakout-room-dialog .css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-top: 8px;
}

.breakout-room-dialog-message {
    margin-bottom: 8px;
}

.breakout-room-dialog-item {
    cursor: pointer;
    margin-top: 5px;
    display: inline-block;
}