* {
    margin: 0;
    padding: 0;
}

.home-display {
    background-color: #0B0C0E;
    height: calc(100vh - 100px);
    display: flex;
}

.home-display button {
    background-color: #3A943A;
    width: 400px;
    height: 128px;
    color: #ffffff;
    font-size: 34px;
    border: none;
    border-radius: 65px ;
    position: relative;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.home-display button[disabled] {
    background-color: #71bd71;
}

.container-snackbar {
    display: flex;
}

.name-content-snackbar {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    word-break: break-word;
}

.avatar-snackbar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.content-snackbar {
    margin-top: 2px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    white-space: pre-wrap;
    max-width: 400px;
    word-break: break-word;
    overflow: hidden;
}

.go2989568495 {
    right: 15px;
}

.go3118922589 {
    top: 24px;
}

.request-join-mtg > span{
    display: block;
    margin-bottom: 8px;
}

.list-btn {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 100;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: all 0.5s;
}

.list-btn .btn {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 20px 0 0 20px;
    background-color: var(--mui-color-mode-four);
    cursor: pointer;
    border: 1px solid var(--mui-color-mode-five);
    border-right: 0;
}

.list-btn .btn svg {
    margin-top: 10px;
    margin-left: 12px;
}

.list-btn .btn img {
    width: 20px;
    margin-top: 10px;
    margin-left: 12px;
}

.list-btn .btn-chat {
    position: relative;
}

.container-display .container-meeting-display.show-list-user-sharing:not(.list-user-display-horizontal) ~ .btn-subtitle {
    right: 198px;
}

.container-display.narrow .container-meeting-display.show-list-user-sharing:not(.list-user-display-horizontal) ~ .btn-subtitle {
    right: 548px;
}

.emoji-snackbar {
    transform: translateY(-10px);
}

.emoji-snackbar img {
    width: 40px;
    transform: translateY(8px);
    margin-left: 5px;
    margin-right: 5px;
}

.container-display {
    transition: all 0.5s;
}
