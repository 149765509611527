.button-icon {
    position: relative;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    border: none;
    background-color: #FFFFFF;
}

.button-icon img {
    width: 24px;
    margin-top: 4px
}

.button-icon svg {
    margin-top: 3px
}

.button-icon:hover {
    cursor: pointer;
}

.button-icon.bg-red {
    position: relative;
    background-color: #FFFFFF;
}

.button-icon.bg-blue {
    background-color: #3386CC;
}

.icon-features {
    translate: 0px 4px;
    color: #ffffff;
}

.icon-off {
    color: var(--mui-error)
}

.icon-recording {
    color: var(--mui-error);
}

.material-icons {
    color: #FFFFFF;
    font-size: 26px !important;
    margin-top: 3px;
}

.error-device svg {
    margin-top: 0;
}