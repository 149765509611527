.date-picker {
    margin-bottom: 20px;
}

.date-picker .start-date-picker {
    margin-right: 10px;
}

.totaling-page .totaling td {
    font-weight: bold;
}