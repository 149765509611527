.create-room {
    text-align: right;
    margin-bottom: 12px;
}

.there-are-users {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: #17AE8C;
    margin-right: 5px;
    border-radius: 50%;
}

.clipboard-link {
    margin-left: 5px;
    cursor: pointer;
    transform: translateY(1px);
    display: inline-block;
}

.access-log {
    margin-left: 5px;
    cursor: pointer;
    transform: translateY(3px);
    display: inline-block;
}

.url-recv-only {
    margin-top: 8px;
    margin-bottom: 8px;
}

.url-recv-only a {
    text-decoration: none;
}

.message-is-disable-edit {
    color: rgb(242, 90, 90);;
}

.manager-room-list {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.manager-room-list .manager-room {
    border: solid 1px #666;
    border-radius: 25px;
    padding: 8px 15px;
    font-size: 16px;
}

.manager-room-list .manager-room .remove-manager {
    transform: translateY(3px);
    margin-left: 10px;
    font-size: 18px;
    color: #666666;
    cursor: pointer;
}

.manager-room-list .manager-room .disable-remove-manage {
    cursor: auto;
}

.account-name.visibility-hidden {
    visibility: hidden;
}

.img-room-access-entry {
    width: 16px;
    margin-right: 4px;
    transform: translateY(2px);
}
