.error-popup {
    width: 100%;
    height: 125px;
    text-align: center;
    background-color: #D62343;
    position: absolute;
    top: 0;
    left: 0;
}

.error-popup span {
    margin-top: 37px;
    display: block;
    font-size: 44px;
    color: #ffffff;
}