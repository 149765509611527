.container-frame {
    position: relative;
    width: 100%;
    height: 100%;
}

.avatar-background-color {
    border: 3px solid #E0E0E0;
}

.container-frame video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.camera-mic {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    color: white;
}

.content-chat {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.65) !important;
}

.content-chat span, .content-chat a {
    left: 30%;
    width: 80%;
    top: 50%;
    text-align: center;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    white-space: pre-wrap;
}

.content-chat a {
    color: white;
}

.background-color-user {
    background-color: #E0E0E0;
}

.background-color-user-not-avatar {
    background-color: #E0E0E0;
}

.background-color-user-not-avatar-1 {
    background-color: #FED5D5;
}

.background-color-user-not-avatar-2 {
    background-color: #C2EBFF;
}

.background-color-user-not-avatar-3 {
    background-color: #A7FAFA;
}

.background-color-user-not-avatar-4 {
    background-color: #B9FAC8;
}

.background-color-user-not-avatar-5 {
    background-color: #C2FAA7;
}

.background-color-user-not-avatar-6 {
    background-color: #FFEC8A;
}

.background-color-user-not-avatar-7 {
    background-color: #FFD7BD;
}

.background-color-user-not-avatar-8 {
    background-color: #C9E8FF;
}

.background-color-user-not-avatar-9 {
    background-color: #E6D9FF;
}

.background-color-user-not-avatar-10 {
    background-color: #EDE0C7;
}

.background-color-user-not-avatar-11 {
    background-color: #FFD1DD;
}

.container-frame.is-has-volume::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    z-index: 1;
    border: 3px solid #3386CC;
}

.camera-mic.is-has-video {
    background: unset;
}

.camera-mic .container-avatar img {
    position: absolute;
    border-radius: 50%;
    max-width: 200px;
    height: 80%;
    max-height: 200px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
}

.container-frame.is-has-volume .camera-mic .container-avatar img {
    height: calc(80% - 3px);
    max-height: 197px;
    max-width: 197px;
}

.container-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.container-icon .button-icon {
    cursor: auto;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.6);
}

.container-icon .button-icon img {
    height: 15px;
    width: auto;
}

.container-icon .button-icon .icon-features {
    cursor: pointer;
}

.container-frame .name-user {
    position: absolute;
    z-index: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 0 1px 2px rgba(0,0,0,.6), 0 0 2px rgba(0,0,0,.3);
    white-space: nowrap;
    font-weight: 500;
    width: max-content;
    max-width: 200px;
    color: #ffffff;
    font-size: 14px;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    top: 10px;
    left: 10px;
}

.list-user-display.is-screen-sharing .container-frame .name-user {
    top: 1px;
    left: 1px;
    font-size: 10px;
}

.mine_share {
    background-color: rgba(0,0,0,.7) !important;
}

.user-frame-action {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0,0,0,0.6);
    border-radius: 20px;
    display: none;
}

.action-item {
    width: 40px;
    height: 40px;
}

.action-item .button-icon {
    width: 40px;
    height: 40px;
    background-color: unset;
    border-radius: 20px;
}

.action-item .button-icon:hover {
    background-color: rgba(0, 0, 0, 0.65);
}

.action-item .button-icon img {
    width: 20px;
    margin-top: 1px;
}

.mouse-move:hover .user-frame-action {
    display: flex;
}

.container-frame .name-share-none {
    display: none;
}

.container-frame.mirroring-webcam > video {
    transform: rotateY(180deg);
}

