.theme-popover {
    padding: 22px 16px;
}

.theme-popover > p {
    font-size: 12px;
    color: var(--mui-text-primary);
}

.theme-popover .theme-mode {
    cursor: pointer;
}

.theme-popover .theme-color-switching {
    margin-top: 10px;
}

.theme-popover > p > svg {
    margin-right: 5px;
    transform: translateY(2px);
}

.theme-popover .theme-color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    transform: translateY(2px);
    cursor: pointer;
}

.theme-popover .theme-color-mode {
    background-color: var(--mui-color-mode-main);
}

.theme-color-list {
    display: flex;
    padding-left: 26px;
    margin-top: 4px;
}

.slate-blue {
    background-color: #3E4696;
}

.steel-blue {
    background-color: #577C8E;
}

.warm-gray {
    background-color: #B7A7A8;
}

.dusty-rose {
    background-color: #B98F99;
}

.blue-gray {
    background-color: #6E7F86;
}