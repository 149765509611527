.timer-setting {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 20;
}

.timer-setting .timer-setting-icon {
    text-align: center;
    height: 100%;
}

.timer-setting .timer-setting-icon svg {
    transform: translateY(10px);
}

.timer-setting .timer-setting-icon > span {
    display: block;
    width: 100%;
    height: 100%;
}

.timer-setting .timer-setting-dialog {
    padding: 10px;
    width: 139px;
    height: 98px;
    bottom: calc(100% + 5px);
    right: 0;
    position: absolute;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0.25rem 0.5625rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 1.25rem -0.0625rem rgba(0, 0, 0, 0.05);
    text-align: center;
    display: none;
}

.timer-setting .timer-setting-dialog.show {
    display: block;
}

.timer-setting .timer-setting-dialog .timer-input {
    display: flex;
    justify-content: center;
}

.timer-setting .timer-setting-dialog .timer-setting-dialog-title {
    font-size: 14px;
    font-weight: bold;
    color: #666666;
}

.timer-setting .timer-setting-dialog .timer-input input {
    width: 60px;
    height: 30px;
    outline: none;
    border: none;
    border-bottom: 1px solid #EFEFEF;
    border-top: 1px solid #EFEFEF;
    padding: 0 10px;
    font-size: 14px;
    color: #666666;
}

.timer-setting .timer-setting-dialog .timer-input span {
    width: 30px;
    height: 30px;
    background-color: #EFEFEF;
    padding-top: 3px;
}

.timer-setting .timer-setting-dialog .timer-input .btn-minus {
    border-radius: 5px 0 0 5px;
}

.timer-setting .timer-setting-dialog .timer-input .btn-plus {
    border-radius: 0 5px 5px 0;
}

.timer-setting .timer-setting-dialog .btn-stop {
    font-size: 14px;
    color: #3386CC;
}

.timer-setting.show-countdown {
    background-color: var(--mui-error);
}

.timer-setting.show-countdown .timer-setting-icon > span {
    line-height: 38px;
}

.timer-setting .countdown {
    color: #ffffff;
    font-size: 14px;
}

@media only screen and (max-width: 991px) {
    .timer-setting {
        position: fixed;
    }
}

.blinker-class {
    animation: blink 1s linear infinite;
}

@keyframes blink {
    25% {
        background-color: var(--mui-error);
    }
    50% {
        background-color: #484848;
    }
    75% {
        background-color: var(--mui-error);
    }
}

