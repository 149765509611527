.subtitle-container {
    position: absolute;
    bottom: 70px;
    left: 40px;
    color: #FFFFFF;
    max-width: calc(100% - 50px);
}

.subtitle-container .subtitle {
    margin-top: 5px;
    background-color: rgba(0,0,0,0.4);
    width: max-content;
    max-width: 100%;
}

.subtitle-container .subtitle .subtitle-content {
    color: #E0E0E0;
}

.subtitle-container .subtitle .subtitle-content.is-final {
    color: #FFFFFF;
}