.error-device-content {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px;
}

.error-device-content li {
    margin-bottom: 8px;
}
