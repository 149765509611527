.container-meeting-display {
    position: relative;
}

.list-user {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: #232323;
    position: relative;
    overflow: hidden;
    justify-content: center;
}

.list-user-display.is-screen-sharing .list-user {
    background-color: var(--mui-color-mode-main);
}

.screen-sharing {
    width: 100%;
    height: 100vh;
    position: relative;
}

.screen-sharing .share-header {
    width: 100%;
    background-color: var(--mui-color-mode-three);
    height: 50px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    position: relative;
}

.screen-sharing .share-header .share-name {
    display: flex;
    flex-wrap: nowrap;
}

.screen-sharing .share-name > span {
    font-size: 14px;
    color: var(--mui-text-color-share);
    display: inline-block;
    line-height: 50px;
}

.screen-sharing .share-name > svg {
    margin-right: 5px;
    margin-left: 16px;
}

.screen-sharing .share-name > img.is-avatar {
    width: 34px;
    height: 34px;
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 5px;
    border-radius: 50%;
}

.screen-sharing .stop-share-btn {
    font-size: 14px;
    color: #3386CC;
    cursor: pointer;
    margin-right: 16px;
    line-height: 50px;
}

.screen-sharing .container-frame {
    height: calc(100% - 50px);
    background-color: var(--mui-color-mode-two);
}

.screen-sharing .container-frame video {
    padding: 0 44px;
    object-fit: contain;
}

.screen-sharing .btn-member-outline {
    width: 40px;
    height: 40px;
    border-radius: 20px 0 0 20px;
    background-color: var(--mui-color-mode-four);
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(calc(-50% - 90px));
    z-index: 1;
    cursor: pointer;
    border: 1px solid var(--mui-color-mode-five);
    border-right: 0;
}

.screen-sharing .btn-member-outline.meeting-owner {
    transform: translateY(calc(-50% - 113px));
}

.screen-sharing .btn-member-outline svg {
    margin-top: 9px;
    margin-left: 12px;
}

.list-user-display.is-screen-sharing {
    height: calc(100vh - 50px);
    position: absolute;
    top: 50px;
    background-color: var(--mui-color-mode-main);
    padding: 0 10px;
    right: -198px;
    display: none;
    z-index: 2;
}

.show-list-user-sharing {
    position: relative;
}

.show-list-user-sharing .list-user-display.is-screen-sharing {
    right: 0;
    display: block;
}

.list-user-display.is-screen-sharing .list-user-scrollbar {
    height: max-content;
    width: calc(100% - 20px);
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    padding: 35px 0;
}

.list-user-display.is-screen-sharing .list-user-scrollbar .list-user {
    max-height: calc(100vh - 150px);
    overflow-y: scroll;
}

.list-user-display.is-screen-sharing .list-user-scrollbar .list-user::-webkit-scrollbar {
    display: none;
}

.list-user-display.is-screen-sharing .list-user-scrollbar .list-user {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.list-user-display.is-screen-sharing .container-frame {
    width: 100% !important;
    height: auto !important;
    aspect-ratio: 16 / 9;
}

.list-user-display.is-screen-sharing .container-frame .container-icon {
    top: 1px;
    right: 1px;
}

.btn-scrollbar-list {
    display: none;
}

.list-user-display.is-screen-sharing .list-user-scrollbar .btn-scrollbar-list {
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

.list-user-display.is-screen-sharing .list-user-scrollbar .btn-scrollbar-list img {
    width: 21px;
}

.btn-scrollbar-top {
    top: 0;
}

.btn-scrollbar-bottom {
    bottom: 0;
}

.btn-scrollbar-bottom img {
    transform: translateY(10px);
}

.show-list-user-sharing .screen-sharing .container-frame video {
    padding: 0;
}

.list-user-display.is-screen-sharing .container-frame.pin__on {
    display: none;
}

.list-user-display.is-screen-sharing .container-frame:nth-child(1) {
    bottom: unset;
    right: unset;
}

.list-user-display.is-screen-sharing .list-user {
    align-content: flex-start;
}

.list-user-display.is-screen-sharing .camera-mic .container-avatar img {
    max-width: 80px;
    max-height: 80px;
}

.list-user-display.is-screen-sharing .container-frame.order-1 {
    order: 1;
}

.list-user-display.is-screen-sharing .container-frame.order-2 {
    order: 2;
}

.list-user-display.is-screen-sharing .container-frame.order-3 {
    order: 3;
}

.list-user-display.is-screen-sharing .container-frame.order-4 {
    order: 4;
}

@media only screen and (min-width: 992px) {
    .list-user-display:not(.is-screen-sharing) .list-user.length-2 .container-frame:nth-child(1) {
        width: 266px !important;
        height: 150px !important;
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 1;
    }

    .list-user-display:not(.is-screen-sharing) .list-user.length-2 .container-frame:nth-child(1) .camera-mic .container-avatar img {
        max-width: 100px;
        max-height: 100px;
    }
}

@media only screen and (max-width: 991px) {
    .list-user {
        min-height: 100vh;
        display: flex;
        align-content: center;
        overflow-y: auto;
    }

    .list-user-display.is-screen-sharing .list-user {
        min-height: unset;
    }

    .list-user-display:not(.is-screen-sharing) .list-user .container-frame {
        width: 50% !important;
        height: 150px !important;
    }

    .list-user-display:not(.is-screen-sharing) .list-user .container-avatar img {
        height: 90px;
    }

    .other-users {
        display: none;
    }

    .list-user-display:not(.is-screen-sharing) .list-user.length-1 .container-frame,
    .list-user-display:not(.is-screen-sharing) .list-user.length-2 .container-frame,
    .list-user-display:not(.is-screen-sharing) .list-user.length-3 .container-frame {
        width: 100% !important;
        height: calc(100vw / 1.8) !important;
    }

    .list-user-display:not(.is-screen-sharing) .list-user.length-1 .container-avatar img,
    .list-user-display:not(.is-screen-sharing) .list-user.length-2 .container-avatar img,
    .list-user-display:not(.is-screen-sharing) .list-user.length-3 .container-avatar img {
        height: 80%;
    }

    .container-icon {
        top: 5px;
        right: 5px;
    }

    .container-frame .name-user {
        top: 5px;
        left: 5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .list-user-display:not(.is-screen-sharing) .list-user .container-frame {
        height: 250px !important;
    }

    .list-user-display:not(.is-screen-sharing) .list-user .container-avatar img {
        height: 150px;
    }
}

.show-list-user-sharing.list-user-display-horizontal .list-user-display.is-screen-sharing {
    width: 100%;
    top: 50px;
    left: 0;
    padding: 10px 0;
}

.list-user-display-horizontal .list-user-display.is-screen-sharing .list-user-scrollbar {
    height: 100%;
    width: 100%;
    top: unset;
    transform: unset;
    padding: 0 35px;
    position: unset;
}

.list-user-display-horizontal .list-user-display.is-screen-sharing .list-user-scrollbar .list-user {
    max-height: 100%;
    width: fit-content;
    flex-wrap: nowrap;
    overflow-x: scroll;
    justify-content: start;
    max-width: calc(100% - 75px);
    left: 50%;
    transform: translateX(-50%);
}

.list-user-display-horizontal.show-list-user-sharing .screen-sharing .container-frame {
    width: 100%;
}

.list-user-display-horizontal .list-user-display.is-screen-sharing .list-user-scrollbar .btn-scrollbar-list {
    left: unset;
    top: 50%;
    transform: translateY(-50%);
    height: 21px;
    width: 26px;
}

.list-user-display-horizontal .list-user-display.is-screen-sharing .list-user-scrollbar .btn-scrollbar-list.btn-scrollbar-top {
    left: 12px;
}

.list-user-display-horizontal .list-user-display.is-screen-sharing .list-user-scrollbar .btn-scrollbar-list.btn-scrollbar-bottom {
    right: 8px;
}

.list-user-display-horizontal .list-user-display.is-screen-sharing .list-user-scrollbar .btn-scrollbar-list img {
    transform: rotate(-90deg);
}

.list-user-display-horizontal .list-user-display.is-screen-sharing .container-frame {
    min-width: 178px !important;
}

.list-user-display-horizontal .screen-sharing .btn-member-outline {
    top: 35px;
    right: -5px;
    transform: translateX(-50%) rotate(-90deg);
}

.list-user-display-horizontal .screen-sharing .btn-member-outline svg {
    transform: rotate(90deg);
    margin-left: 6px;
}

.icon-change-layout {
    z-index: 1;
    position: absolute !important;
    right: 4px;
    top: 2px;
    display: none !important;
    padding: 0 6px !important;
}

.show-list-user-sharing .is-screen-sharing .icon-change-layout {
    display: inline-block !important;
}

.list-user-display-horizontal .icon-change-layout {
    transform: rotate(0deg);
    right: 9px;
    top: 3px;
}

.list-user-display-horizontal .list-user-display.is-screen-sharing .container-frame {
    min-width: auto !important;
}

.list-user-display-horizontal .screen-sharing .btn-member-outline svg {
    margin-top: 11px;
    margin-left: 5px;
}

.list-user-handle-width {
    cursor: col-resize;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    background-color: transparent;
}

.list-user-display-horizontal .list-user-handle-width {
    cursor: row-resize;
    width: 100%;
    height: 10px;
    bottom: 0;
    top: unset;
}
