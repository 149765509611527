.room-detail-item {
    margin-bottom: 20px;
    padding-left: 10px;
}

.room-detail-item > span {
    color: var(--mui-text-primary);
    font-size: 14px;
}

.room-detail-item .room-detail-item-title {
    display: block;
    font-size: 16px;
    font-weight: 500;
}

.room-detail-item .room-detail-item-data {
    padding-left: 10px;
    display: block;
    font-size: 14px;
}

.room-detail-item .checkboxes_tags svg, .room-detail-item .checkboxes_tags svg:hover {
    color: var(--mui-text-primary);
}

.room-detail-item .ai-prompt-select .MuiInputBase-root:before {
    border-color: #FFFFFF !important;
}

.room-detail-item .ai-prompt-select * {
    color: #FFFFFF;
}