.page-error {
    height: 100vh;
}

.page-error .container {
    position: relative;
    top: 50%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    transform: translateY(-50%);
}

.page-error .content {
    font-size: 30px;
    margin: 15px 0px;
    color: var(--mui-text-primary);
}

.page-error .error-code {
    font-weight: 500;
}

.page-error .separation {
    display: block;
    width: 1px;
    height: 30px;
    background-color: var(--mui-text-primary);
    margin: 0 20px;
    transform: translateY(4px);
}