.setting-device-quickly {
    background-color: #2c2c2c;
    width: 480px;
    height: 54px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-devices {
    display: flex;
    align-items: center;
    width: 460px;
    height: 30px;
    gap: 10px;
    justify-content: flex-end;
}

.device {
    flex: 2;
    border: 1px solid #FFFFFF;
    border-radius: 20px;
    width: 40%;
    height: 40px;
    background-color: #2c2c2c;
    cursor: pointer;
}

.device .device-mic, .device .device-camera, .device .device-setting, .device .device-speaker {
    display: flex;
    margin: 6px;
}

.device img {
    width: 16px;
    margin-right: 10px;
    margin-left: 4px;
}