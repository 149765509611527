header {
    width: 100%;
    padding: 15px 15px;
    display: flex;
    justify-content: space-between;
}

header .header-link {
    text-align: right;
}

header .logo img {
    height: 30px;
}

header .login, header .logout, header .open-guest-dialog {
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
}

.user-profile-cushion-page .user-name {
    font-size: 14px;
    color: #ffffff;
    margin-left: 5px;
}

.user-profile-cushion-page .user-profile-avatar {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
}