.ac-icon-record {
    width: 18px;
}

.record-progressing {
    transform: translateY(-14px);
    display: inline-block;
}

.record-summary * {
    margin: revert;
    padding: revert;
}

.record-transcriptions {
    white-space: pre-line;
}

.btn-action[disabled] {
    opacity: 0.5;
}
