.modal-device-error {
    position: relative;
    max-width: 420px;
    height: 360px;
    top: 50%;
    left: 50%;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    outline: none;
}

.modal-device-error .container-box-error {
    padding: 50px 30px 30px 30px
}

.modal-device-error .container-box-error .image-error {
    margin-left: 16px;
    margin-bottom: 10px;
}

.modal-device-error .container-box-error .image-error img{
    width: 320px;
    height: auto;
}

.modal-device-error .container-box-error p{
    margin-bottom: 10px;
}

.modal-device-error .button-dismiss {
    font-size: 16px;
    position: absolute;
    right: 30px;
    bottom: 30px;
    text-decoration: none;
    cursor: pointer;
}

.modal-device-error .button-cancel {
    font-size: 16px;
    position: absolute;
    right: 80px;
    bottom: 30px;
    text-decoration: none;
    cursor: pointer;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 12px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    transform: translateX(18px);
}

.beauty-effects {
    display: flex;
    gap: 20px;
}

.beauty-effects > span {
    color: var(--mui-text-primary);
}

.beauty-effects input[type="range"] {
    margin-left: 5px;
}

.media-processors {
    display: flex;
    gap: 20px;
    justify-content: space-between;
}

.media-processors .media-processor-name {
    display: block;
    color: var(--mui-text-primary);
    font-size: 16px;
    padding-top: 15px;
    padding-bottom: 10px;
}
