.data-aggregation .data-aggregation-date {
    width: 100%;
    border-bottom: 1px solid #e3e9ef;
    min-height: 50px;
    display: flex;
    gap: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.data-aggregation .data-aggregation-day {
    display: inline-block;
    flex-basis: 90px;
    flex-shrink: 0;
}

.data-aggregation .data-aggregation-meetings {
    width: 100%;
    display: flex;
    flex-flow: column;
    gap: 10px;
}

.data-aggregation .data-aggregation-meeting {
    width: 100%;
    background-color: #E0E0E0;
}

.data-aggregation .data-aggregation-meeting .meeting-title {
    color: #666666;
    margin-left: 5px;
    margin-right: 10px;
}

.data-aggregation .data-aggregation-meeting .meeting-start-end {
    color: #666666;
}

.data-aggregation .data-aggregation-meeting .meeting-time {
    position: relative;
}

.data-aggregation .data-aggregation-meeting .meeting-start {
    position: absolute;
    left: 0;
}

.data-aggregation .data-aggregation-meeting .meeting-end {
    position: absolute;
    right: 0;
}

.data-aggregation .data-aggregation-meeting .meeting-data {
    width: 100%;
    height: 25px;
    margin-bottom: 5px;
    position: relative;
}

.data-aggregation .data-aggregation-meeting .meeting-img {
    height: 100%;
    position: absolute;
}

.data-aggregation .data-aggregation-meeting .meeting-user-img {
    border-radius: 50%;
}

.data-aggregation-loading {
    text-align: center;
}
