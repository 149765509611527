#emoji-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: 0;
}

.emoji-particles {
    position: absolute;
    bottom: 0;
    font-size: 2em;
    opacity: 0;
    animation: floatUp 2s infinite ease-in;
    animation-iteration-count: 1;
}

.emoji-particles img {
    width: 100%;
}

.emoji-list {
    width: 224px;
    height: 190px;
    gap: 8px;
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
}

.emoji-list .emoji-item {
    width: 28px;
    height: 28px;
    cursor: pointer;
}

.emoji-quantity {
    flex-grow: 1;
    position: relative;
}

.emoji-quantity select {
    width: 60px;
    float: right;
    border: 1px solid #E06666;
    padding: 5px 5px 5px 5px;
    border-radius: 6px;
    outline: none;
    margin-top: 2px;
    color: #ffffff;
    background-color: #E06666;
}

.emoji-quantity select option {
    background-color: #ffffff;
    color: #E06666;
}

@keyframes floatUp {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }
    100% {
        transform: translateY(-80vh);
        opacity: 0;
    }
}
