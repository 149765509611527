.chat {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.container-select-object-chat {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--mui-text-primary);
}

.select-object-chat {
    width: 100%;
    padding: 8px 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
}

.select-object-chat option {
    padding: 10px;
    font-size: 16px;
}

.chat-body {
    overflow-y: auto;
    height: auto;
    flex-grow: 1;
    margin-bottom: 20px;
}

.chat-body .chat-item {
    display: flex;
    gap: 8px;
}

.chat-body .avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #E0E0E0;
}

.chat-body .chat-name {
    font-size: 14px;
    color: var(--mui-text-primary);
    margin-right: 10px
}

.chat-body .chat-name-object {
    color: #93C47D;
}

.chat-body .chat-at {
    font-size: 14px;
    color: var(--mui-text-secondary);
}

.chat-body .chat-message {
    display: block;
    font-size: 14px;
    color: var(--mui-text-secondary);
    word-break: break-word;
    margin-top: 2px;
    margin-bottom: 8px;
    white-space: pre-line;
}

.chat-footer {
    position: relative;
}

.input-message {
    display: flex;
    background-color: #F1F3F4;
    border: 1px solid #E3E9EF;
    border-radius: 8px;
}

.chat-footer textarea {
    width: 280px;
    box-sizing: border-box;
    background-color: #F1F3F4;
    border-radius: 8px;
    border: none;
    padding: 10px 16px 10px 16px;
    outline: none;
    resize: none;
    overflow: auto;
    line-height: 1.5;
}

.chat-footer .send-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.scroll-text {
    overflow: hidden !important;
}

.chat-footer .emoji {
    position: absolute;
}

.chat-footer .emoji {
    right: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    height: 20px;
}

.chat-emoji {
    width: 20px;
    transform: translateY(4px);
}

.chat-item .chat-emoji.only-emoji {
    width: 40px;
    transform: translateY(0px);
}