* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Noto Sans JP", sans-serif;
    overflow-y: auto !important;
    padding-right: 0 !important;
}

a {
    color: #3386CC;
}

div::-webkit-scrollbar {
    background: transparent;
    height: 8px;
    width: 8px;
}

div::-webkit-scrollbar-thumb {
    border: none;
    box-shadow: none;
    background: #dadce0;
    border-radius: 4px;
    min-height: 40px;
}

#react-confirm-alert-firm-svg {
    display: none;
}

#root {
    background-color: var(--mui-background-default);
}