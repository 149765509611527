.ai-prompt-dialog .options {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.ai-prompt-dialog .option {
    border: solid 1px #666;
    border-radius: 25px;
    padding: 4px 12px;
    font-size: 16px;
    cursor: pointer;
}
